import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { getHookFormValidationRules } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { useEffect } from 'react';
import { combineIntoFormElementName, getFilterLabel, stackLabelWithContainerProps } from './shared';
import { FilterProps } from './types';
import FormAutocompleteFreesolo from '@cfra-nextgen-frontend/shared/src/components/Form/FormAutocompleteFreesolo';

type ScreenerFormAutocompleteFreesoloProps = {} & FilterProps;

export const ScreenerFormAutocompleteSingleSelect: React.FC<ScreenerFormAutocompleteFreesoloProps> = ({
    control,
    filtersData,
    filterMetadataKey,
    layoutProps,
    submitHandler,
    useFormLabelWithContainer,
    hide,
    component,
    fieldDefaultStyles = {},
    parentSectionKey,
    onChangeClearHandler,
    emitDefaultValues,
}) => {
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const filterSectionMetadata = filterMetadata.sections[parentSectionKey];
    const defaultValue = filterMetadata.default_value as string
    const options = filtersData.data?.[filterMetadataKey].items || [];
    const validationRules = getHookFormValidationRules(filtersData, filterMetadataKey, parentSectionKey);
    const label = getFilterLabel(filterMetadata, parentSectionKey);

    useEffect(() => {
        if (defaultValue && emitDefaultValues) {
            emitDefaultValues({
                [combineIntoFormElementName({
                    componentName: component || Components.AutoCompleteFreeSolo,
                    filterMetadataKey,
                })]: defaultValue,
            });
        }
    }, [defaultValue, emitDefaultValues, component, filterMetadataKey]);

    const filter = (
        <FormAutocompleteFreesolo
            control={control}
            name={combineIntoFormElementName({
                componentName: component || Components.AutoCompleteFreeSolo,
                filterMetadataKey,
            })}
            validationRules={validationRules}
            options={options}
            placeholder={filterMetadata.placeholder || label}
            defaultValue={defaultValue}
            submitHandler={submitHandler}
            fieldDefaultStyles={fieldDefaultStyles}
            onChangeClearHandler={onChangeClearHandler}
            disabled={filterMetadata.disabled}
            readOnly={filterMetadata.makeReadOnlyOnDefaultValues && defaultValue ? true : false}
        />
    );

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer
            label={label}
            layoutProps={layoutProps}
            wrapperStyles={filterSectionMetadata.wrapper_styles}
            hide={hide}
            {...(filterSectionMetadata?.stack_label_and_filter && stackLabelWithContainerProps)}>
            {filter}
        </FormLabelWithContainer>
    );
};



//-------Needed for single select autocomplete ------------

// import FormAutocompleteSingleSelect from '@cfra-nextgen-frontend/shared/src/components/Form/AutoCompleteSingleSelect';
// import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
// import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
// import { combineIntoFormElementName, getFilterLabel, stackLabelWithContainerProps } from './shared';
// import { FilterProps } from './types';
// import { SxProps, Theme } from '@mui/material';

// type ScreenerFormVirtualizeAutocompleteProps = {
//     defaultInputLabel?: string;
//     inputSxProps?: SxProps;
//     theme?: Theme;
//     ignoreFocus?: boolean;
//     placeholder?: string;
//     autocompleteSxProps?: SxProps;
// } & FilterProps;


// export const ScreenerFormAutocompleteSingleSelect: React.FC<ScreenerFormVirtualizeAutocompleteProps> = ({
//     control,
//     filtersData,
//     filterMetadataKey,
//     layoutProps,
//     submitHandler,
//     useFormLabelWithContainer,
//     defaultInputLabel,
//     hide,
//     component,
//     parentSectionKey,
//     onChangeClearHandler,
//     theme,
//     ignoreFocus,
//     placeholder,
//     autocompleteSxProps,
// }) => {
//     const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
//     const stackLabelWithContainer = filterMetadata.sections?.[parentSectionKey]?.stack_label_and_filter;
//     const label = getFilterLabel(filterMetadata, parentSectionKey);
//     const placeholderText = placeholder;

    
//     const filter = (
//         <FormAutocompleteSingleSelect
//             label={label}
//             control={control}
//             name={combineIntoFormElementName({
//                 componentName: component || Components.Autocomplete,
//                 filterMetadataKey,
//             })}
//             enableCount={false}
//             options={filtersData.data[filterMetadataKey].items}
//             placeholder={placeholderText}
//             defaultValues={filterMetadata.default_values}
//             submitHandler={submitHandler}
//             defaultInputLabel={defaultInputLabel}
//             onChangeClearHandler={onChangeClearHandler}
//             theme={theme}
//             ignoreFocus={ignoreFocus}
//             alwaysShowPlaceholder={true}
//             autocompleteSxProps={autocompleteSxProps}
//         />
//     );

//     if (useFormLabelWithContainer === false) {
//         return filter;
//     }

//     return (
//         <FormLabelWithContainer
//             label={label}
//             layoutProps={layoutProps}
//             hide={hide}
//             {...(stackLabelWithContainer && stackLabelWithContainerProps)}>
//             {filter}
//         </FormLabelWithContainer>
//     );
// };
