import { OperationTypes } from "@cfra-nextgen-frontend/shared/src/utils/enums";
import { UserPlatformManagementEntityTypes } from "@cfra-nextgen-frontend/shared/src/utils/enums";
import { FiltersData } from "../../Form/types/filters";
import { modifyFormRequestBody } from "../components/Profile/utils";

export const modifyRequestBodyFromFilterMetadata = ({
    filtersData, requestBody, entityType, operationType, entityId
}:{
    filtersData: FiltersData, 
    requestBody: Array<Record<string, any>> | Record<string, any>,
    entityType: UserPlatformManagementEntityTypes,
    operationType:OperationTypes,
    entityId: number | undefined
}) => {
    let metaData = filtersData.filter_metadata;

    Object.keys(metaData)
        .filter(
            (key) =>
                (metaData[key].dropField?.create || false) &&
                metaData[key].item_metadata.response_mapping?.default !== undefined,
        )
        .forEach((key) => {
            let fieldName = metaData[key].item_metadata.response_mapping?.default;
            if (fieldName !== undefined) {
                if (Array.isArray(requestBody)) {
                    requestBody.forEach((item) => {
                        delete item[fieldName!];
                    });
                    return;
                }
                delete requestBody[fieldName];
            }
            requestBody = modifyFormRequestBody(requestBody, entityType, operationType, entityId);
            return '';
        });
    
    if (entityType === UserPlatformManagementEntityTypes.Group && operationType === OperationTypes.CREATE) {
        if (!Array.isArray(requestBody)) {
            requestBody['account_id'] = entityId;
        }
    }

    if (
        entityType === UserPlatformManagementEntityTypes.User_Alert_Subscription &&
        operationType === OperationTypes.CREATE
    ) {
        if (requestBody['alert_type' as keyof typeof requestBody]?.length > 0) {
            let newBody = requestBody['alert_type' as keyof typeof requestBody].map((item: any) => {
                let newItem: Record<string, any> = {}

                newItem['user_id'] = entityId;
                newItem['is_active'] = requestBody['is_active' as keyof typeof requestBody];
                newItem['delivery_type'] = requestBody['value' as keyof typeof requestBody].delivery_type;
                newItem['alert_type'] = Number(
                    item.key_value.startsWith('user_management.lookup_alert_type.lookup_id')
                        ? item.key_value.replace('user_management.lookup_alert_type.lookup_id_|_', '')
                        : item.fk_value,
                );

                newItem['alert_subscription_package_id'] = item.key_value.startsWith(
                    'user_management.alert_subscription_package.id',
                )
                    ? Number(item.key_value.replace('user_management.alert_subscription_package.id_|_', ''))
                    : null;

                return newItem;
            });
            requestBody = newBody
        }
    }

    return requestBody;
};